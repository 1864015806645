<template>
    <div>
        <div
            v-if="metaAdvertising"
            class="filtered-channel">
            <img
                class="filtered-logo"
                :src="`/img/platforms/meta-blue.svg`">
            <span>Vehicle Catalog</span>
        </div>
        <div
            v-if="googleMarketplace || googleAdvertising"
            class="filtered-channel">
            <img
                class="filtered-logo"
                :src="`/img/platforms/google.svg`">
            <span v-if="googleAdvertising">Vehicle Catalog</span>
            <span v-if="googleAdvertising && googleMarketplace">, &nbsp;</span>
            <span v-if="googleMarketplace">Marketplace</span>
        </div>
        <div
            v-if="microsoftMarketplace || microsoftAdvertising"
            class="filtered-channel">
            <img
                class="filtered-logo"
                :src="`/img/platforms/microsoft.svg`">
            <span v-if="microsoftAdvertising">Vehicle Catalog</span>
            <span v-if="microsoftAdvertising && microsoftAdvertising">, &nbsp;</span>
            <span v-if="microsoftMarketplace">Marketplace</span>
        </div>
        <div
            v-if="pinterestAdvertising"
            class="filtered-channel">
            <img
                class="filtered-logo"
                :src="`/img/platforms/pinterest.svg`">
            <span>Vehicle Catalog</span>
        </div>
        <div
            v-if="tiktokAdvertising"
            class="filtered-channel">
            <img
                class="filtered-logo"
                :src="`/img/platforms/tiktok.svg`">
            <span>Vehicle Catalog</span>
        </div>
        <div
            v-if="snapchatAdvertising"
            class="filtered-channel">
            <img
                class="filtered-logo"
                :src="`/img/platforms/snapchat.svg`">
            <span>Vehicle Catalog</span>
        </div>
        <div
            v-if="affectedExports.includes('amazon_auto')"
            class="filtered-channel">
            <img
                class="filtered-logo"
                :src="`/img/platforms/amazon.svg`">
            <span>Vehicle Catalog</span>
        </div>
    </div>
</template>

<script>
import { inventoryChannels } from '@/helpers/inventory-dashboard/channels';
import {
    PLATFORM_FACEBOOK,
    PLATFORM_GOOGLE,
    PLATFORM_MICROSOFT,
    PLATFORM_PINTEREST,
    PLATFORM_TIKTOK,
    PLATFORM_SNAPCHAT,
    PRODUCT_GROUP_ADVERTISING,
    PRODUCT_GROUP_MARKETPLACE,
} from '@/helpers/globals';

export default {
    props: {
        affectedExports: {
            type: Array,
            required: true
        }
    },
    computed: {
        metaAdvertising() {
            const metaAdvertising = inventoryChannels.find(c => c.channel === PLATFORM_FACEBOOK);
            if (this.affectedExports.includes(metaAdvertising.aggregation)) return true;
            return false;
        },
        googleMarketplace() {
            const googleMarketplace = inventoryChannels.find(c => c.channel === PLATFORM_GOOGLE && c.channel_type === PRODUCT_GROUP_MARKETPLACE);
            if (this.affectedExports.includes(googleMarketplace.aggregation)) return true;
            return false;
        },
        googleAdvertising() {
            const googleAdvertising = inventoryChannels.find(c => c.channel === PLATFORM_GOOGLE && c.channel_type === PRODUCT_GROUP_ADVERTISING);
            if (this.affectedExports.includes(googleAdvertising.aggregation)) return true;
            return false;
        },
        microsoftMarketplace() {
            const microsoftMarketplace = inventoryChannels.find(c => c.channel === PLATFORM_MICROSOFT && c.channel_type === PRODUCT_GROUP_MARKETPLACE);
            if (this.affectedExports.includes(microsoftMarketplace.aggregation)) return true;
            return false;
        },
        microsoftAdvertising() {
            const microsoftAdvertising = inventoryChannels.find(c => c.channel === PLATFORM_MICROSOFT && c.channel_type === PRODUCT_GROUP_ADVERTISING);
            if (this.affectedExports.includes(microsoftAdvertising.aggregation)) return true;
            return false;
        },
        pinterestAdvertising() {
            const pinterestAdvertising = inventoryChannels.find(c => c.channel === PLATFORM_PINTEREST);
            if (this.affectedExports.includes(pinterestAdvertising.aggregation)) return true;
            return false;
        },
        tiktokAdvertising() {
            const tiktokAdvertising = inventoryChannels.find(c => c.channel === PLATFORM_TIKTOK);
            if (this.affectedExports.includes(tiktokAdvertising.aggregation)) return true;
            return false;
        },
        snapchatAdvertising() {
            const snapchatAdvertising = inventoryChannels.find(c => c.channel === PLATFORM_SNAPCHAT);
            if (this.affectedExports.includes(snapchatAdvertising.aggregation)) return true;
            return false;
        },
    },
};
</script>
<style scoped>
.filtered-channel {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
}
.filtered-logo {
    max-width: 20px;
    height: auto;
    margin-right: 10px;
}
</style>