<template>
    <div>
        <styled-tooltip
            v-model="showFilteredDropdown"
            position="left"
            :max-width="485"
            :high-index="true"
            :no-padding="true"
            :rounded-border="true">
            <div
                v-if="reasonsStructurized.length"
                class="filtered-link"
                @click="showFilteredDropdown = true">
                {{ reasonsStructurized.length }} Filtered Reasons
            </div>
            <template #content>
                <div class="filtered-content">
                    <div class="filtered-titles">
                        <div class="filtered-title">
                            Filtered Reasons
                        </div>
                        <div class="filtered-title">
                            Affected Exports
                        </div>
                    </div>
                    <div>
                        <div
                            v-for="(reason, index) in reasonsStructurized"
                            :key="index"
                            class="filtered-block">
                            <div class="filtered-reason">
                                {{ reason.reason }}
                            </div>
                            <div class="filtered-channels">
                                <affected-exports :affected-exports="reason.channels" />
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </styled-tooltip>
    </div>
</template>

<script>
import StyledTooltip from '@/components/globals/StyledTooltip';
import AffectedExports from '@/components/inventory-dashboard/AffectedExports';
import { mapState } from 'vuex';
import { PINTEREST_ADVERTISING_KEY, MICROSOFT_MARKETPLACE_KEY } from '@/helpers/globals';

export default {
    components: {
        StyledTooltip,
        AffectedExports
    },
    props: {
        item: {
            type: Object,
            required: true
        },
    },
    computed: {
        ...mapState({
            activatedChannels: state => state.settings.activatedChannels,
        })
    },
    watch: {
        activatedChannels() {
            this.setReasons();
        }
    },
    data() {
        return {
            showFilteredDropdown: false,
            filteredReasons: [],
            reasonsStructurized: []
        };
    },
    mounted() {
        this.setReasons();
    },
    methods: {
        setReasons() {
            // convert everything from separate exports to have all reasons grouped together
            this.filteredReasons = [];
            const allReasons = new Set();
            // eslint-disable-next-line
            const channelsForChecking = structuredClone(this.activatedChannels);
            channelsForChecking.push('amazon_auto'); // check amazon as well
            channelsForChecking.forEach(channel => {
                const channelReasons = [];
                const reasons = this.item.exports[channel].filtered_vehicle_reasons;
                if (reasons.length) {
                    reasons.forEach(reason => {
                        const reasonObj = {
                            field: reason.reason,
                            channel
                        };
                        channelReasons.push(reasonObj);
                        allReasons.add(reason.reason);
                    });
                }
                if (channelReasons.length) {
                    this.filteredReasons.push(channelReasons);
                }
            });
            // now let's add appropriate channels to reasons to have markup like in design
            const allReasonsArr = Array.from(allReasons);
            this.reasonsStructurized = [];
            allReasonsArr.forEach(reason => {
                const channelsSet = new Set();
                this.filteredReasons.forEach(r => {
                    r.forEach(e => {
                        if (e.field == reason) {
                            channelsSet.add(e.channel);
                        }
                    });
                });
                const singleReason = {
                    reason,
                    channels: Array.from(channelsSet)
                };
                this.reasonsStructurized.push(singleReason);
            });
            this.setClass();
        },
        setClass() {
            // Adding red background if the vehicle has filtered reasons.
            // For some reasons red color is not applied!
            if (this.filteredReasons.length) {
                this.filteredReasons.forEach(channelReasons => {
                    channelReasons.forEach(reason => {
                        // 'Not Applicable' reasons
                        const NA1 = reason.channel === PINTEREST_ADVERTISING_KEY && reason.field === 'The vehicle must be a new vehicle.';
                        const NA2 = reason.channel === MICROSOFT_MARKETPLACE_KEY && reason.field === 'The vehicle must be a used vehicle.';
                        const NA3 = reason.channel === MICROSOFT_MARKETPLACE_KEY && reason.field === 'The mileage must be at least 500.';
                        const NA4 = reason.channel === MICROSOFT_MARKETPLACE_KEY && reason.field === 'The created at must be a date after or equal to 90 days ago.';
                        if (!NA1 && !NA2 && !NA3 && !NA4) {
                            this.$emit('set-class', 'red-bg');
                        }
                    });
                });
            } else {
                this.$emit('set-class', '');
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.filtered-content {
    width: 485px;
    font-size: 10px;
    box-sizing: border-box;
    border-radius: 10px;
    overflow: hidden;
}
.filtered-titles {
    display: flex;
    padding: 15px 25px;
}
.filtered-title {
    color: $blue-bg;
    width: 50%;
}
.filtered-block {
    padding: 10px 35px;
    display: flex;
    &:nth-of-type(odd) {
        background-color: #F8F8F8;
    }
}
.filtered-reason {
    width: 50%;
    padding-right: 15px;
}
.filtered-channels {
    width: 50%;
}
.filtered-link {
    color: $blue-bg;
    text-decoration: underline;
    white-space: nowrap;
    cursor: pointer;
    &:hover {
        text-decoration: none;
    }
}
</style>